<template>
  <div class='log'>
    <div class="action">
      <div>
        <span>所属模块：</span>
        <el-select clearable v-model="queryParams.module" placeholder="请选择" @change="filterChange" size="small">
          <el-option v-for="item in moduleList" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
        <span>日期选择：</span>
        <el-date-picker v-model="date" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" size="small" @change="dateChange">
        </el-date-picker>
        <span>日志类型：</span>
        <el-select clearable v-model="queryParams.type" placeholder="请选择日志类型" @change="filterChange" size="small">
          <el-option v-for="item in logTypeList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" size="small">
      <el-table-column prop="module" label="所属模块">
      </el-table-column>
      <el-table-column prop="type" label="日志类型">
        <template slot-scope="{row}">
          <!-- 1:登录，2：登出，3：添加，4：删除，5：修改，6：查询 -->
          <span v-if="row.type === 1">登录</span>
          <span v-if="row.type === 2">登出</span>
          <span v-if="row.type === 3">添加</span>
          <span v-if="row.type === 4">删除</span>
          <span v-if="row.type === 5">修改</span>
          <span v-if="row.type === 6">查询</span>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="操作人">
      </el-table-column>
      <el-table-column prop="content" label="操作内容">
      </el-table-column>
      <el-table-column prop="operateTime" label="操作时间">
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.current" :limit.sync="queryParams.size" @pagination="getList" />
  </div>
</template>

<script>
import { getLogList } from '@/api'
export default {
  data () {
    return {
      // 时间
      date: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      // 表格数据
      tableData: [],
      queryParams: {
        size: 10,
        current: 1,
        platforms: 8,
        module: '',
        startTime: '',
        endTime: '',
        type: null
      },
      total: 0,
      moduleList: ['登录模块', '权限管理', '人员管理', '单位管理', '设备管理', '供应商管理'],
      // 1:登录，2：登出，3：添加，4：删除，5：修改，6：查询
      logTypeList: [
        { id: 1, name: '登录' },
        { id: 2, name: '登出' },
        { id: 3, name: '添加' },
        { id: 4, name: '删除' },
        { id: 5, name: '修改' },
        { id: 6, name: '查询' }
      ]
    };
  },
  created () {
    this.getList()
  },
  mounted () {

  },
  methods: {
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    },
    async getList () {
      const res = await getLogList(this.queryParams)
      // console.log('res :>> ', res);
      this.total = res.total
      this.tableData = res.data
    },
    // 筛选
    filterChange () {
      this.queryParams.current = 1
      this.getList()
    },
    // 日期选择
    dateChange (val) {
      if (val) {
        this.queryParams.startTime = this.$moment(val[0]).format('YYYY-MM-DD HH:mm:ss')
        this.queryParams.endTime = this.$moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParams.startTime = ''
        this.queryParams.endTime = ''
      }
      this.filterChange()
    }
  }
};
</script>

<style scoped lang="scss">
.log {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
    > div {
      span {
        // font-size: 16px;
        color: rgb(95, 94, 94);
        margin: 0 5px 0 30px;
      }
      // .el-select,
      // .el-input {
      //   width: 200px;
      // }
    }
  }
}
</style>
